import { useContext, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useLocation } from "react-router-dom"

import { useQuery } from "@apollo/client"
import { loader } from "graphql.macro"
import { observer } from "mobx-react-lite"
import Store from "store"

import { Message } from "components/Atoms/Message"
import { PageTitle } from "components/Atoms/Page/Title"
import { EmptyState } from "components/Molecules/EmptyState"
import { Home, HomeProps } from "components/Molecules/Home"
import { LoadingHome } from "components/Organisms/Loading/Homes"

const GET_HOMES = loader("../../gql/homes.gql")

export const Regions = observer(() => {
  const store = useContext(Store)
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [homes, setHomes] = useState({ count: 0, results: [] })
  const regionName = location.pathname.replace("/regions/", "")
  const region = store.getRegionByName(regionName)

  // Set region
  if (region) {
    store.setRegion(region.id)
  }

  // Get Homes
  const { loading, error, data, fetchMore } = useQuery(GET_HOMES, {
    variables: {
      region: region && region.id,
      period: {
        checkIn: store.startDate,
        checkOut: store.endDate,
      },
      guests: Number(store.guests),
      order: store.order,
      page: 1,
      pageSize: 10,
    },
    onCompleted: data => {
      if (page === 1) {
        setHomes(data.homes)
      }
    },
  })

  if (loading) {
    return <LoadingHome />
  }

  if (error || (data && data.homes?.count === 0)) {
    return (
      <EmptyState
        onButtonClick={() => {
          store.resetFilters()
        }}
      />
    )
  }

  const onFetchMore = () => {
    fetchMore({
      variables: {
        page: page + 1,
      },
    }).then(fetchMoreResults => {
      const data = fetchMoreResults.data

      // Update Homes
      setHomes((prevState: any) => {
        return {
          ...prevState,
          results: [...prevState.results, ...data.homes.results],
        }
      })

      // Update Page
      setPage((prevState: number) => prevState + 1)
    })
  }

  const renderHomes = (data: any) => {
    return (
      data.results.length > 0 &&
      data.results.map((home: HomeProps) => {
        return <Home {...home} key={home.id} />
      })
    )
  }

  return (
    <>
      <PageTitle
        label="Your stay in one of"
        subtitle="homes"
        title={data.homes.count}
      />
      <InfiniteScroll
        dataLength={homes.results.length}
        endMessage={<Message text="Yay! You have seen it all" />}
        hasMore={homes.count > homes.results.length}
        loader={<Message text="Loading more homes..." />}
        next={onFetchMore}
        scrollThreshold={1}
      >
        <div>{renderHomes(homes)}</div>
      </InfiniteScroll>
    </>
  )
})
