import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 150px auto 0;
  padding: 40px 20px;
  max-width: ${(props: { maxWidth: number }) =>
    props.maxWidth && `${props.maxWidth}px`};
`
