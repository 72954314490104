import styled from "styled-components"

import colors from "styles/colors"

export const StyledLine = styled.span`
  display: block;
  background-color: ${colors.neutral.light};
  margin: ${(props: { width?: number; height?: number; margin?: string }) =>
    props.margin ? props.margin : "0 0 4px"};
  width: ${(props: { width?: number; height?: number; margin?: string }) =>
    props.width ? `${props.width}px` : "132px"};
  height: ${(props: { width?: number; height?: number; margin?: string }) =>
    props.height ? `${props.height}px` : "17px"};
`
