import React from "react"

import { StyledButton } from "./styles"

export interface Props {
  primary?: boolean
  border?: boolean
  label?: string
  onClick?: () => void
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type ButtonProps = Props & NativeAttrs

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  primary = false,
  border = true,
  label,
  ...props
}): JSX.Element => {
  return (
    <StyledButton border={border} primary={primary} type="button" {...props}>
      {label}
    </StyledButton>
  )
}
