import React from "react"

import { Wrapper } from "./styles"

export interface Props {
  maxWidth?: number
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type PageWrapperProps = Props & NativeAttrs

export const PageWrapper: React.FC<
  React.PropsWithChildren<PageWrapperProps>
> = ({ maxWidth = 800, ...props }): JSX.Element => {
  return <Wrapper maxWidth={maxWidth} {...props} />
}
