import React from "react"
import { Link } from "react-router-dom"

import logoText from "./assets/logo-text.svg"
import logo from "./assets/logo.svg"

export interface Props {}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type LogoProps = Props & NativeAttrs

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({
  ...props
}): JSX.Element => {
  return (
    <Link to="/">
      <picture {...props}>
        <source media="(min-width: 768px)" srcSet={logoText} />
        <img alt="AvantStay Logo" src={logo} />
      </picture>
    </Link>
  )
}
