import { createContext } from "react"

import { format } from "date-fns"
import { isEmpty, omitBy } from "lodash"
import { makeAutoObservable, toJS } from "mobx"

export class Store {
  regions: any = []
  region: string = ""
  startDate: string = ""
  endDate: string = ""
  guests: string = ""
  order: string = "RELEVANCE"
  coupon: string = ""
  routeParams: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  resetFilters = () => {
    this.region = ""
    this.startDate = ""
    this.endDate = ""
    this.guests = ""
    this.order = "RELEVANCE"
    this.coupon = ""
    this.routeParams = ""
  }

  getRegions = () => {
    return toJS(this.regions)
  }

  getRegionByName = (name: string) => {
    const region = this.getRegions().filter((region: any) => {
      return region.name.toLowerCase() === name.toLowerCase()
    })

    return toJS(region[0])
  }

  getRouteParams() {
    return this.routeParams
  }

  setRegions = (regions: any) => {
    this.regions = regions
  }

  setRegion = (region: string) => {
    this.region = region
    this.setRouteParams()
  }

  setPeriod = (period: any) => {
    if (period[0]) {
      this.startDate = format(period[0], "yyyy-MM-dd")
    }

    if (period[1]) {
      this.endDate = format(period[1], "yyyy-MM-dd")
    }

    this.setRouteParams()
  }

  setGuests = (guests: string) => {
    this.guests = guests
    this.setRouteParams()
  }

  setOrder = (order: string) => {
    this.order = order
    this.setRouteParams()
  }

  setCoupon = (coupon: string) => {
    this.coupon = coupon
    this.setRouteParams()
  }

  setRouteParams() {
    const filters = {
      region: this.region,
      startDate: this.startDate,
      endDate: this.endDate,
      guests: this.guests,
      order: this.order,
      coupon: this.coupon,
    }

    this.routeParams = new URLSearchParams(omitBy(filters, isEmpty)).toString()
  }
}

export default createContext(new Store())
