import React from "react"

import { StyledLine } from "./styles"

export interface Props {
  width?: number
  height?: number
  margin?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type LineProps = Props & NativeAttrs

export const Line: React.FC<React.PropsWithChildren<LineProps>> = ({
  width = 132,
  height = 17,
  margin,
  ...props
}): JSX.Element => {
  return <StyledLine height={height} margin={margin} width={width} {...props} />
}
