export const guests: any = Array.from(Array(30), (item, index) => {
  return {
    id: index + 1,
    name: `${index + 1} ${index + 1 > 1 ? "guests" : "guest"}`,
  }
})

export const order = [
  {
    id: "RELEVANCE",
    name: "Relevance",
  },
  {
    id: "PRICE_DESC",
    name: "Price: Highest First",
  },
  {
    id: "PRICE_ASC",
    name: "Price: Lowest First",
  },
]
