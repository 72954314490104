import styled from "styled-components"
import { Swiper as _Swiper } from "swiper/react"

import colors from "styles/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 122%;
  letter-spacing: -0.01em;

  &:not(:last-child) {
    padding: 20px 0;
    border-bottom: 1px solid ${colors.primary.lighter};
  }

  &:last-child {
    padding-top: 20px;
    margin-bottom: 55px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const Swiper = styled(_Swiper)`
  width: 100%;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    width: 390px;
    height: 208px;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 30px;
  }

  .swiper-pagination-bullet {
    background: ${colors.neutral.medium};
    opacity: 1;

    &-active {
      background: ${colors.accent.light};
    }
  }
`

export const Photo = styled.img`
  width: 100%;
  height: auto;
  background-color: ${colors.neutral.light};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Location = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  color: ${colors.accent.medium};
`

export const LocationButton = styled.button`
  padding: 0;
  background-color: transparent;

  :focus,
  :hover {
    color: ${colors.primary.medium};
  }
`

export const Separator = styled.span`
  padding: 0 6px;

  &:before {
    content: "•";
  }
`

export const Title = styled.a`
  margin: 0 0 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 114%;
  color: ${colors.primary.medium};

  :focus,
  :hover {
    color: ${colors.accent.dark};
    text-decoration: none;
  }
`

export const Amenities = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 122%;
  color: ${colors.primary.medium};
  opacity: 0.5;

  @media (min-width: 768px) {
    margin-bottom: 36px;
  }
`

export const Amenity = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 16px;
  }
`

export const Icon = styled.img`
  svg {
    font-size: 24px;
  }
`

export const Prices = styled.div`
  display: flex;
  flex-direction: row;
`

export const PriceItem = styled.div`
  &:not(:last-child) {
    margin-right: 60px;
  }

  @media (min-width: 768px) {
    margin-bottom: 16px;
  }
`

export const PriceLabel = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: ${colors.primary.medium};
  opacity: 0.5;
`

export const PriceValue = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  margin-bottom: 4px;
`
