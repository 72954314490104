import styled from "styled-components"

import colors from "styles/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props: { margin?: string }) =>
    props.margin ? props.margin : null};
`

export const Text = styled.span`
  padding: 5px 16px;
  background-color: ${colors.neutral.light};
  color: ${colors.neutral.medium};
`
