import styled from "styled-components"

import colors from "styles/colors"

export const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 25px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.01em;

  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;

  color: ${(props: { primary: boolean }) =>
    props.primary ? colors.primary.medium : colors.accent.medium};
  border-color: ${(props: { primary: boolean; border: boolean }) =>
    props.border
      ? props.primary
        ? colors.primary.medium
        : colors.accent.medium
      : "transparent"};

  &:hover {
    color: ${(props: { primary: boolean }) =>
      props.primary ? colors.accent.medium : colors.primary.light};
    border-color: ${(props: { primary: boolean; border: boolean }) =>
      props.border
        ? props.primary
          ? colors.accent.medium
          : colors.primary.light
        : "transparent"};
    cursor: pointer;
  }

  &.active {
    color: ${(props: { primary: boolean }) =>
      props.primary ? colors.accent.dark : colors.primary.medium};
    border-color: ${(props: { primary: boolean; border: boolean }) =>
      props.border
        ? props.primary
          ? colors.accent.dark
          : colors.primary.medium
        : "transparent"};
  }
`
