import React from "react"
import { useContext } from "react"

import { useQuery } from "@apollo/client"
import { loader } from "graphql.macro"
import { kebabCase } from "lodash"
import Store from "store"
import { Pagination } from "swiper"
import { SwiperSlide } from "swiper/react"

import { Line } from "components/Atoms/Line"

import iconBath from "./assets/bath.svg"
import iconHigh from "./assets/high.svg"
import iconLow from "./assets/low.svg"
import iconPool from "./assets/pool.svg"
import iconRooms from "./assets/rooms.svg"
import iconUser from "./assets/user.svg"
import {
  Amenities,
  Amenity,
  Content,
  Icon,
  Location,
  LocationButton,
  Photo,
  PriceItem,
  PriceLabel,
  Prices,
  PriceValue,
  Separator,
  Swiper,
  Title,
  Wrapper,
} from "./styles"

const GET_HOMES_PRICING = loader("../../../gql/homes_pricing.gql")

export interface PhotoProps {
  listOrder: number
  url: string
}

export interface SeasonPricingProps {
  highSeason: {
    minPrice: number
    maxPrice: number
  }
  lowSeason: {
    minPrice: number
    maxPrice: number
  }
}

export interface HomePricingProps {
  homeId: string
  numberOfNights: number
  total: number
}

export interface Props {
  id: string
  title: string
  description: string
  photos: Array<PhotoProps>
  roomCounts?: number
  bathroomsCount?: number
  bedsCount?: number
  maxOccupancy?: number
  hasPool?: boolean
  amenities?: string[]
  seasonPricing?: SeasonPricingProps
  homePricing?: HomePricingProps
  regionName?: string
  cityName?: string
  stateName?: string
  stateCode?: string
  onClick?: () => void
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type HomeProps = Props & NativeAttrs

export const Home: React.FC<React.PropsWithChildren<HomeProps>> = ({
  id,
  title,
  description,
  photos,
  roomCounts,
  bathroomsCount,
  bedsCount,
  maxOccupancy,
  hasPool,
  amenities,
  seasonPricing,
  homePricing,
  regionName,
  cityName,
  stateName,
  stateCode,
  onClick,
  ...props
}): JSX.Element => {
  const store = useContext(Store)
  const { startDate, endDate, coupon } = store

  const { loading, data } = useQuery(GET_HOMES_PRICING, {
    variables: {
      ids: id,
      period: {
        checkIn: startDate,
        checkOut: endDate,
      },
      coupon: coupon,
    },
    skip: startDate && endDate ? false : true,
  })

  const pricing =
    data && data.homesPricing[0]
      ? data.homesPricing[0]
      : homePricing
        ? homePricing
        : undefined

  return (
    <Wrapper {...props}>
      <Swiper
        centeredSlides={true}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        spaceBetween={30}
      >
        {photos.map(photo => (
          <SwiperSlide key={photo.url}>
            <Photo
              alt={title}
              loading="lazy"
              src={`${photo.url}?width=390&height=208&webp=true&quality=50`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Content>
        <Location>
          {regionName && (
            <LocationButton type="button" onClick={onClick}>
              {regionName}
            </LocationButton>
          )}
          <Separator />
          {cityName && <span>{cityName}, </span>}
          {stateName && <span>{stateName}</span>}
        </Location>
        <Title
          href={`https://avantstay.com/${id}/${kebabCase(
            regionName,
          )}/${kebabCase(title)}`}
          target="_blank"
        >
          {title}
        </Title>
        <Amenities>
          {roomCounts && (
            <Amenity>
              <Icon alt="Number of bedrooms" src={iconRooms} />
              {roomCounts} Bedrooms
            </Amenity>
          )}
          {bathroomsCount && (
            <Amenity>
              <Icon
                alt="Number of bathrooms"
                src={iconBath}
                style={{ marginRight: 4 }}
              />
              {bathroomsCount} Bathrooms
            </Amenity>
          )}
          {hasPool && (
            <Amenity>
              <Icon alt="Number of pools" src={iconPool} />
              Pool
            </Amenity>
          )}
          {maxOccupancy && (
            <Amenity>
              <Icon alt="Number of guests" src={iconUser} />
              {maxOccupancy} Guests
            </Amenity>
          )}
        </Amenities>
        <Prices>
          {loading && (
            <PriceItem>
              <Line height={17} width={74} />
              <Line height={22} width={98} />
              <Line height={17} width={45} />
            </PriceItem>
          )}
          {!loading && pricing && pricing.numberOfNights > 0 && (
            <PriceItem>
              <PriceLabel>
                Total <Separator /> {pricing.numberOfNights} nights
              </PriceLabel>
              <PriceValue>${pricing.total.toLocaleString("en-US")}</PriceValue>
              <PriceLabel>
                $
                {(pricing.total / pricing.numberOfNights).toLocaleString(
                  "en-US",
                )}{" "}
                per night
              </PriceLabel>
            </PriceItem>
          )}
          {!loading &&
            (!pricing || pricing.numberOfNights === 0) &&
            seasonPricing &&
            seasonPricing.lowSeason && (
              <PriceItem>
                <PriceLabel>
                  <Icon alt="Budget Season" src={iconLow} />
                  Budget Season
                </PriceLabel>
                <PriceValue>
                  ${seasonPricing.lowSeason.minPrice.toLocaleString("en-US")} -
                  ${seasonPricing.lowSeason.maxPrice.toLocaleString("en-US")}
                </PriceValue>
                <PriceLabel>per night</PriceLabel>
              </PriceItem>
            )}
          {!loading &&
            (!pricing || pricing.numberOfNights === 0) &&
            seasonPricing &&
            seasonPricing.lowSeason && (
              <PriceItem>
                <PriceLabel>
                  <Icon alt="Prime Season" src={iconHigh} />
                  Prime Season
                </PriceLabel>
                <PriceValue>
                  ${seasonPricing.highSeason.minPrice.toLocaleString("en-US")} -
                  ${seasonPricing.highSeason.maxPrice.toLocaleString("en-US")}
                </PriceValue>
                <PriceLabel>per night</PriceLabel>
              </PriceItem>
            )}
        </Prices>
      </Content>
    </Wrapper>
  )
}
