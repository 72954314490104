import { useContext, useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useLocation, useNavigate } from "react-router-dom"

import { useQuery } from "@apollo/client"
import { differenceInDays, isValid } from "date-fns"
import { loader } from "graphql.macro"
import { get } from "lodash"
import { observer } from "mobx-react-lite"
import Store from "store"

import { Message } from "components/Atoms/Message"
import { PageTitle } from "components/Atoms/Page/Title"
import { EmptyState } from "components/Molecules/EmptyState"
import { Home, HomeProps } from "components/Molecules/Home"
import { LoadingHome } from "components/Organisms/Loading/Homes"

const GET_HOMES = loader("../../gql/homes.gql")

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search)
}

const adjustForUTCOffset = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

export const Homes = observer(() => {
  const store = useContext(Store)
  const navigate = useNavigate()
  const query = useQueryParams()
  const [page, setPage] = useState(1)
  const [homes, setHomes] = useState({ count: 0, results: [] })
  const numberOfNights =
    differenceInDays(new Date(store.endDate), new Date(store.startDate)) - 1 ||
    0

  // Get Route Parameters
  useEffect(() => {
    const queryParams = {
      region: query.get("region"),
      startDate: query.get("startDate") || "",
      endDate: query.get("endDate") || "",
      guests: query.get("guests"),
      order: query.get("order"),
      coupon: query.get("coupon"),
    }

    if (store) {
      if (get(queryParams, "region") && queryParams.region !== store.region) {
        store.setRegion(get(queryParams, "region") || "")
      }

      if (get(queryParams, "guests")) {
        store.setGuests(get(queryParams, "guests") || "")
      }

      if (get(queryParams, "order")) {
        store.setOrder(get(queryParams, "order") || "")
      }

      if (get(queryParams, "coupon")) {
        store.setCoupon(get(queryParams, "coupon") || "")
      }

      if (
        isValid(new Date(queryParams.startDate)) &&
        isValid(new Date(queryParams.endDate)) &&
        queryParams.startDate !== store.startDate &&
        queryParams.endDate !== store.endDate
      ) {
        store.setPeriod([
          adjustForUTCOffset(new Date(queryParams.startDate)),
          adjustForUTCOffset(new Date(queryParams.endDate)),
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update Route Parameters
  useEffect(() => {
    navigate(`?${store.routeParams}`)
  }, [navigate, store, store.routeParams])

  // Get Homes
  const { loading, error, data, fetchMore } = useQuery(GET_HOMES, {
    variables: {
      region: store.region,
      period: {
        checkIn: store.startDate,
        checkOut: store.endDate,
      },
      guests: Number(store.guests),
      order: store.order,
      page: page,
      pageSize: 10,
    },
    onCompleted: data => {
      if (page === 1) {
        setHomes(data.homes)
      }
    },
  })

  if (loading) {
    return <LoadingHome />
  }

  if (
    error ||
    (data && data.homes?.count === 0) ||
    (numberOfNights > 0 && (numberOfNights < 3 || numberOfNights > 15))
  ) {
    return (
      <EmptyState
        onButtonClick={() => {
          store.resetFilters()
        }}
      />
    )
  }

  const onFetchMore = () => {
    fetchMore({
      variables: {
        page: page + 1,
      },
    }).then(fetchMoreResults => {
      const data = fetchMoreResults.data

      // Update Homes
      setHomes((prevState: any) => {
        return {
          ...prevState,
          results: [...prevState.results, ...data.homes.results],
        }
      })

      // Update Page
      setPage((prevState: number) => prevState + 1)
    })
  }

  const renderHomes = (data: any) => {
    return (
      data.results.length > 0 &&
      data.results.map((home: HomeProps) => {
        const location = {
          pathname: `regions/${home.regionName?.toLowerCase()}`,
          state: { home },
        }

        return (
          <Home
            {...home}
            key={home.id}
            onClick={() => {
              if (home.regionName) {
                const region = store.getRegionByName(home.regionName)
                store.setRegion(region.id)
              }
              navigate(location)
            }}
          />
        )
      })
    )
  }

  return (
    <>
      <PageTitle
        label="Your stay in one of"
        subtitle="homes"
        title={homes.count.toString()}
      />
      <InfiniteScroll
        dataLength={homes.results.length}
        endMessage={<Message text="Yay! You have seen it all" />}
        hasMore={homes.count > homes.results.length}
        loader={<Message text="Loading more homes..." />}
        next={onFetchMore}
        scrollThreshold={1}
      >
        <div>{renderHomes(homes)}</div>
      </InfiniteScroll>
    </>
  )
})
