import React from "react"

import { Button } from "components/Atoms/Button"

import illustration from "./assets/illustration.svg"
import { Illustration, Message, Wrapper } from "./styles"

export interface Props {
  /**
   * Message
   */
  message?: JSX.Element
  /**
   * Button Label
   */
  buttonLabel?: string
  /**
   * Optional click handler
   */
  onButtonClick?: () => void
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type EmptyStateProps = Props & NativeAttrs

export const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({
  message = (
    <>
      Oops! We haven&apos;t found anything matching your search.
      <br />
      Try something else or reset the filters to see all homes.
    </>
  ),
  buttonLabel = "See all homes",
  onButtonClick,
}): JSX.Element => {
  return (
    <Wrapper>
      <Illustration src={illustration} />
      <Message>{message}</Message>
      {buttonLabel && <Button label={buttonLabel} onClick={onButtonClick} />}
    </Wrapper>
  )
}
