import styled from "styled-components"

import colors from "styles/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  font-family: Source Sans Pro;
  font-style: normal;
  line-height: 122%;
  letter-spacing: -0.01em;
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 9px;
  text-transform: uppercase;
  color: ${colors.accent.medium};

  &:after {
    content: "";
    margin-left: 7px;
    width: 68px;
    height: 1px;
    background-color: ${colors.accent.medium};
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  color: ${colors.primary.medium};
`

export const SubTitle = styled.span`
  font-weight: 300;
  font-size: 28px;
`
