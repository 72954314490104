import React from "react"

import { PageTitle } from "components/Atoms/Page/Title"
import { SkeletonHome } from "components/Molecules/Skeleton/Home"

export interface Props {
  homes?: number
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type LoadingHomeProps = Props & NativeAttrs

export const LoadingHome: React.FC<
  React.PropsWithChildren<LoadingHomeProps>
> = ({ homes = 3 }): JSX.Element => {
  return (
    <>
      <PageTitle label="Please wait" subtitle="homes" title="Loading" />
      <>
        {Array.from(Array(homes), (_item, index) => {
          return <SkeletonHome key={index} />
        })}
      </>
    </>
  )
}
