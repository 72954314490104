import { useContext } from "react"
import { Route, Routes } from "react-router-dom"

import { useQuery } from "@apollo/client"
import { loader } from "graphql.macro"
import { observer } from "mobx-react-lite"
import "rsuite/dist/rsuite.min.css"
import Store from "store"

import { PageWrapper } from "components/Atoms/Page/Wrapper"
import { Header } from "components/Molecules/Header"
import { Homes } from "pages/homes"
import { Regions } from "pages/regions"

import { GlobalStyle } from "./styles/global"

const GET_REGIONS = loader("./gql/regions.gql")

const App = observer(() => {
  const store = useContext(Store)

  useQuery(GET_REGIONS, {
    onCompleted: data => {
      store.setRegions(data.regions)
    },
  })

  return (
    <>
      <GlobalStyle />
      <Header />
      <PageWrapper>
        <Routes>
          <Route element={<Homes />} path="/" />
          <Route element={<Homes />} path="homes" />
          <Route element={<Regions />} path="regions/:regionName" />
        </Routes>
      </PageWrapper>
    </>
  )
})

export default App
