import React from "react"

import { Active, Label, StyledLink } from "./styles"

export interface Props {
  href?: string
  active?: boolean
  arrow?: boolean
  label?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type LinkProps = Props & NativeAttrs

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
  href,
  active = false,
  arrow = false,
  label,
  ...props
}): JSX.Element => {
  return (
    <StyledLink active={active} {...props}>
      <Label active={active} arrow={arrow} href={href}>
        {label}
      </Label>
      {active && <Active />}
    </StyledLink>
  )
}
