import React, { useContext } from "react"

import { observer } from "mobx-react-lite"
import Store from "store"

import { Button } from "components/Atoms/Button"
import { Input } from "components/Atoms/Input"
import { Link } from "components/Atoms/Link"
import { Logo } from "components/Atoms/Logo"

import { guests, order } from "./options"
import {
  Buttons,
  Container,
  Coupon,
  Filters,
  Nav,
  Selectors,
  TopBar,
  Wrapper,
} from "./styles"

export interface Props {}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type HeaderProps = Props & NativeAttrs

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = observer(
  (): JSX.Element => {
    const store = useContext(Store)

    // Set regions
    const defaultRegions = store.getRegions()
    const regions =
      defaultRegions.length > 0
        ? [{ id: "", name: "All regions" }, ...defaultRegions]
        : []

    return (
      <Wrapper>
        <Container>
          <TopBar>
            <Logo onClick={() => store.resetFilters()} />
            <Nav>
              <Link active href="/homes" label="Find Homes" />
              <Link href="#" label="Partners" />
              <Link href="#" label="Company Retreats" />
              <Link arrow href="#" label="More" />
            </Nav>
            <Buttons>
              <Button border={false} label="Sign In" primary={true} />
              <Button border={true} label="Sign Up" primary={true} />
            </Buttons>
          </TopBar>
          <Filters>
            <Selectors>
              <Input
                defaultValue={store.region}
                label="Where"
                name="region"
                options={regions}
                placeholder="All regions"
                type="select"
                onChange={(e: any) => {
                  const value = e.target.value
                  store.setRegion(value)
                }}
              />
              <Input
                defaultValue={[store.startDate, store.endDate]}
                label="When"
                name="date"
                type="daterange"
                onChange={(e: Event) => {
                  store.setPeriod(e)
                }}
              />
              <Input
                defaultValue={Number(store.guests)}
                label="Who"
                name="guests"
                options={guests}
                type="select"
                onChange={(e: any) => {
                  const value = e.target.value
                  store.setGuests(value)
                }}
              />
              <Input
                defaultValue={store.order}
                label="Order"
                name="order"
                options={order}
                type="select"
                onChange={(e: any) => {
                  const value = e.target.value
                  store.setOrder(value)
                }}
              />
            </Selectors>
            <Coupon>
              <Input
                defaultValue={store.coupon}
                label="Coupon"
                name="coupon"
                placeholder="Got a code?"
                type="text"
                onChange={(e: any) => {
                  const value = e.target.value
                  store.setCoupon(value)
                }}
              />
            </Coupon>
          </Filters>
        </Container>
      </Wrapper>
    )
  },
)
