import React from "react"

import { Content, Line, Photo, Wrapper } from "./styles"

export interface Props {}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type SkeletonHomeProps = Props & NativeAttrs

export const SkeletonHome: React.FC<
  React.PropsWithChildren<SkeletonHomeProps>
> = (): JSX.Element => {
  return (
    <Wrapper>
      <Photo />
      <Content>
        <Line height={17} width={132} />
        <Line height={28} width={218} />
        <Line height={17} margin="0 0 40px" width={241} />
        <Line height={17} width={74} />
        <Line height={22} width={98} />
        <Line height={17} width={45} />
      </Content>
    </Wrapper>
  )
}
