import styled from "styled-components"

import colors from "styles/colors"

import chevronDown from "./assets/chevron-down.svg"

export const StyledLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${(props: { active: boolean }) =>
    props.active ? colors.accent.medium : colors.primary.medium};

  &:hover {
    color: ${colors.accent.medium};
    cursor: pointer;
  }
`

export const Label = styled.a`
  display: flex;
  align-items: center;
  color: ${(props: { active: boolean }) =>
    props.active ? colors.accent.medium : colors.primary.medium};
  text-decoration: inherit;

  &:focus,
  &:hover {
    color: ${colors.accent.medium};
    text-decoration: none;
  }

  &:after {
    display: ${(props: { active: boolean; arrow: boolean }) =>
      props.arrow ? "inline-block" : "none"};
    content: url(${chevronDown});
    margin-top: -4px;
    margin-left: 8px;
  }
`

export const Active = styled.span`
  display: block;
  margin-top: 6px;
  width: 20px;
  height: 1px;
  background-color: ${colors.accent.medium};
`
