import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ""-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    letter-spacing: -0.01em;
    background-color: #FFF;
  }

  a, input, select {
    background: none;
    box-shadow: none;
  }

  .rs-picker-daterange {
    min-width: 200px;

    .rs-picker-toggle-active {
      border: none;
      box-shadow: none;

      .rs-picker-toggle-textbox {
        padding-left: 2px !important;
      }
    }
  }
`
