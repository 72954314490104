import styled from "styled-components"

import colors from "styles/colors"

export const StyledInput = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 8px 0;
  border: 2px solid transparent;
  border-radius: 3px;

  &:last-child {
    div {
      border-right: none;
    }
  }

  &:active,
  &:focus,
  &:focus-within,
  &:hover {
    border-color: ${colors.accent.lighter};
  }

  label {
    margin-bottom: 4px;
    font-size: 11px;
    line-height: 122%;
    color: ${colors.accent.medium};
  }

  input {
    border: none;
    outline: none;
    margin-left: -2px;
    color: ${colors.primary.medium};

    ::placeholder {
      color: ${colors.primary.medium};
      opacity: 0.3;
    }
  }

  select {
    border: none;
    outline: none;
    margin-left: -4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 142%;
    color: ${colors.primary.medium};
  }

  .rs-picker {
    &-toggle {
      background-color: transparent;
      padding: 0;

      &-value {
        color: ${colors.primary.medium} !important;
        font-weight: 600;
      }

      &-caret,
      &-clean {
        display: none;
      }

      &.active,
      &:active,
      &:hover,
      &:focus {
        background-color: transparent !important;
      }
    }
  }
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 15px;
  border-right: 1px solid ${colors.primary.extraLight};
`
