import styled from "styled-components"

import colors from "styles/colors"

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  overflow: auto;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 4px 8px 40px rgba(227, 230, 234, 0.3);
  padding: 20px;
`

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
`

export const TopBar = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const Nav = styled.nav`
  display: flex;
  min-width: 400px;

  a {
    margin: 0 12px;

    @media (min-width: 768px) {
      margin: 0 16px;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  min-width: 200px;

  @media (min-width: 768px) {
    justify-content: end;
  }
`

export const Filters = styled.div`
  display: flex;
  background-color: #fff;
`

export const Selectors = styled.div`
  display: flex;
  flex: 1;
  margin-right: 10px;
  border: 1px solid ${colors.primary.extraLight};
  border-radius: 3px;
`

export const Coupon = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    border: 1px solid ${colors.primary.extraLight};
    border-radius: 3px;
  }
`
