import React from "react"

import { DateRangePicker } from "rsuite"

import { InputBox, StyledInput } from "./styles"

export interface SelectProps {
  id: string
  name: string
}

export interface Props {
  name: string
  label?: string
  type: string
  defaultValue?: any
  options?: Array<SelectProps>
  placeholder?: string
  onChange?: (e: any) => void
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type InputProps = Props & NativeAttrs

const adjustForUTCOffset = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

const renderInput = (
  name: string,
  type: string,
  defaultValue?: any,
  options?: Array<SelectProps>,
  placeholder?: string,
  onChange?: (e: any) => void,
): React.ReactNode => {
  switch (type) {
    case "text": {
      return (
        <input
          id={name}
          name={name}
          placeholder={placeholder}
          type="text"
          onChange={onChange}
        />
      )
    }

    case "select": {
      const selectOptions =
        options &&
        options.map(option => (
          <option
            key={option.id}
            selected={option.id === defaultValue}
            value={option.id}
          >
            {option.name}
          </option>
        ))

      return (
        <select id={name} name={name} onChange={onChange}>
          <option disabled hidden value="0">
            {placeholder || "Select..."}
          </option>
          {selectOptions}
        </select>
      )
    }

    case "daterange": {
      let props: object = {}

      if (defaultValue[0] && defaultValue[1]) {
        props = {
          defaultValue: [
            adjustForUTCOffset(new Date(defaultValue[0])),
            adjustForUTCOffset(new Date(defaultValue[1])),
          ],
        }
      }

      return (
        <DateRangePicker
          key={defaultValue.toString()}
          appearance="subtle"
          placeholder="Select..."
          onOk={onChange}
          {...props}
        />
      )
    }
  }
}

export const Input: React.FC<React.PropsWithChildren<InputProps>> = ({
  name,
  label = "",
  type,
  defaultValue,
  options = [],
  placeholder,
  onChange,
  ...props
}): JSX.Element => {
  return (
    <StyledInput {...props}>
      <InputBox>
        <label htmlFor={name}>{label}</label>
        {renderInput(name, type, defaultValue, options, placeholder, onChange)}
      </InputBox>
    </StyledInput>
  )
}
