import styled from "styled-components"

import { Line as _Line } from "components/Atoms/Line"

import colors from "styles/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  &:not(:last-child) {
    padding: 20px 0;
    border-bottom: 1px solid ${colors.primary.lighter};
  }

  &:last-child {
    padding-top: 20px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const Photo = styled.div`
  width: 390px;
  height: 208px;
  max-width: 100%;
  margin-right: 30px;
  background-color: ${colors.neutral.light};
  border: none;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0;
`

export const Line = styled(_Line)`
  max-width: 100%;
`
