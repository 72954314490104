import styled from "styled-components"

import colors from "styles/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 235px auto;
`

export const Illustration = styled.img`
  max-width: 185px;
  margin-bottom: 40px;
`

export const Message = styled.span`
  margin-bottom: 30px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 154%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${colors.neutral.dark};
`
