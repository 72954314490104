import React from "react"

import { Text, Wrapper } from "./styles"

export interface Props {
  text: string
  margin?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type MessageProps = Props & NativeAttrs

export const Message: React.FC<React.PropsWithChildren<MessageProps>> = ({
  text,
  margin,
  ...props
}): JSX.Element => {
  return (
    <Wrapper margin={margin}>
      <Text {...props}>{text}</Text>
    </Wrapper>
  )
}
