import React from "react"

import { Label, SubTitle, Title, Wrapper } from "./styles"

export interface Props {
  label?: string
  title?: string
  subtitle?: string
}

type NativeAttrs = Omit<React.HTMLAttributes<any>, keyof Props>
export type PageTitleProps = Props & NativeAttrs

export const PageTitle: React.FC<React.PropsWithChildren<PageTitleProps>> = ({
  label,
  title,
  subtitle,
  ...props
}): JSX.Element => {
  return (
    <Wrapper {...props}>
      {label && <Label>{label}</Label>}
      {title && (
        <Title>
          {title} {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </Title>
      )}
    </Wrapper>
  )
}
