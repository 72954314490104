const colors = {
  primary: {
    light: "#1C5D9F",
    lighter: "#F4F7FA",
    extraLight: "#E8EFF5",
    medium: "#022B54",
  },
  accent: {
    lighter: "#D1EFF2",
    light: "#A3DFE6",
    medium: "#53C3D0",
    dark: "#34AEBC",
  },
  neutral: {
    light: "#F7F7F7",
    medium: "#B3B3B3",
    dark: "#505051",
  },
}

export default colors
